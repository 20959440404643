<template>
  <div class="attract_cont">
     <!-- 车位概况卡片 -->
    <el-card class="parking">
      <div class="parking_title">
        <h2>车位概况</h2>
      </div>
      <el-form :model="getParking_situation" ref="FormCont" label-width="95px" size="small " class="parkingFrom">
        <el-form-item label="车位总数:" prop="car_num" :rules="[{ type: 'number', message: '必须为数字值'}]">
          <el-input type="age" @change="situation" v-model.number="getParking_situation.car_num" autocomplete="off"></el-input>
          <span> 个</span>
        </el-form-item>
        <el-form-item label="已出租:" prop="have_rent" :rules="[{ type: 'number', message: '必须为数字值'}]">
          <el-input type="age" @change="situation" v-model.number="getParking_situation.have_rent" autocomplete="off"></el-input>
          <span> 个</span>
        </el-form-item>
        <el-form-item label="未出租:">
          <el-input :disabled="true"  v-model.number="calculation_not_rent" autocomplete="off"></el-input>
          <span> 个</span>
        </el-form-item>
        <el-form-item label="出租率:">
          <el-input :disabled="true"  v-model.number="calculation_occupancy_rate" autocomplete="off"></el-input>
          <span> %</span>
        </el-form-item>
      </el-form>
    </el-card>
    <!-- 园区整体运营情况卡片 -->
    <el-card class="operate">
      <div class="operate_title">
        <h2>园区整体运营情况</h2>
      </div>
      <!-- 第一条运营情况的标题 -->
      <div class="operTitle_cont">
        <span>楼栋号</span><span>可租凭面积(㎡)</span><span>出租状态</span><span>出租对象</span>
      </div>
      <!-- 渲染添加的内容 -->
      <el-form ref="operateForm" v-for="(item, index) in the_whole_operation" :key="index + '_1'" :model="item" label-position="top" size="small" label-width="80px" class="operateForm">
        <div class="oper_input">
          <el-input :class="item.house_num == '' ? 'red_error' : ''" @input="operateChange_timer(index, item.id)" v-model="item.house_num" size="small"></el-input>
        </div>
        <div class="oper_input">
          <el-input :class="item.area == '' ? 'red_error' : ''" @input="operateChange_timer(index, item.id)" v-model="item.area" size="small"></el-input>
        </div>
        <div class="oper_input">
          <el-select @input="operateChange(index, item.id)" v-model="item.status" size="small">
            <el-option label="已出租" value="已出租"></el-option>
            <el-option label="意向选址" value="意向选址"></el-option>
            <el-option label="招商中" value="招商中"></el-option>
            <el-option label="自用" value="自用"></el-option>
          </el-select>
        </div>
        <div class="oper_input">
          <el-input :class="item.object == '' ? 'red_error' : ''" @input="operateChange_timer(index, item.id)" v-model="item.object" size="small"></el-input>
        </div>
        <div class="form_btn">
          <el-button v-if="index >= 1" class="numberInput"  @click="removeChange(item.id)" type="text" icon="el-icon-minus"></el-button>
          <el-button class="numberInput"  @click="addChange()" type="text" icon="el-icon-plus"></el-button>
        </div>
      </el-form>
      <!-- 13号楼-->
      <h2 class="operTitle">13号楼</h2>
      <div class="operTitle_cont">
        <span>楼层</span><span>楼层面积(㎡)</span><span>出租状态</span><span>出租对象</span>
      </div>
      <!-- 渲染添加的内容 -->
      <el-form ref="operateForm13" v-for="(item, index) in the_floor_data" :key="index+'_2'" :model="item" label-position="top" size="small" label-width="80px" class="operateForm">
        <div class="oper_input">
          <el-input :class="item.floor == '' ? 'red_error' : ''" @input="operateChange_timer2(index, item.id)" v-model="item.floor" size="small"></el-input>
        </div>
        <div class="oper_input">
          <el-input :class="item.floor_area == '' ? 'red_error' : ''" @input="operateChange_timer2(index, item.id)" v-model="item.floor_area" size="small"></el-input>
        </div>
        <div class="oper_input">
          <el-select @input="operateChange13(index, item.id)" v-model="item.status" size="small">
            <el-option label="已出租" value="已出租"></el-option>
            <el-option label="意向选址" value="意向选址"></el-option>
            <el-option label="招商中" value="招商中"></el-option>
            <el-option label="自用" value="自用"></el-option>
          </el-select>
        </div>
        <div class="oper_input">
          <el-input :class="item.object == '' ? 'red_error' : ''" @input="operateChange_timer2(index, item.id)" v-model="item.object" size="small"></el-input>
        </div>
        <div class="form_btn">
          <el-button v-if="index >= 1" class="numberInput"  @click="removeChange13(item.id)" type="text" icon="el-icon-minus"></el-button>
          <el-button class="numberInput"  @click="addChange13()" type="text" icon="el-icon-plus"></el-button>
        </div>
      </el-form>
    </el-card>
    <!-- 配套设施 -->
    <el-card class="facilities">
      <div class="facilities_title">
        <h2>配套设施</h2>
      </div>
      <el-button type="primary" class="facilities_btn" @click="dialogVisible = true">新增配套设施</el-button>
      <el-table :data="tableData" border style="width: 80%" class="facilities_table">
        <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
        <el-table-column prop="photo" label="封面" align="center">
          <template slot-scope="scope">
            <el-image :src="serverAddress + scope.row.photo" lazy fit="fill"></el-image>
            <!-- <img :src="serverAddress + scope.row.photo" alt=""> -->
          </template>
        </el-table-column>
        <el-table-column prop="name" label="配套设施名称" align="center"></el-table-column>
        <el-table-column prop="address" label="所处地址" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.address ? scope.row.address : '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="introduce" label="简介介绍" align="center"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button type="text" @click="showEditDialog(scope.row.id)">编辑</el-button>
            <!-- 删除按钮 -->
            <el-button type="text"  @click="removeInfo(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
     <!-- 新增配套设施的对话框 -->
    <el-dialog :visible.sync="dialogVisible2">
      <img width="100%" :src="imageUrl" alt="">
    </el-dialog>
    <el-dialog title="新增配套设施" :visible.sync="dialogVisible" :closeOnClickModal="false" :center="true" width="50%">
        <!-- 内容主体区 -->
        <el-form id='addFormRef' ref="addFormRef" :model="supporting_measure" :rules="addRules"  label-width="100px">
          <el-form-item label="封面：">
            <el-upload
              class="avatar-uploader"
              action=""
              accept="image/jpeg,image/gif,image/png"
              name="photo"
              :on-change="onUploadChange"
              :auto-upload="false"
              :show-file-list="false">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <div v-if="imageUrl" class="el-upload-list--picture-card">
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-preview"
                    @click.stop="show_uploaded_image"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span
                    class="el-upload-list__item-delete"
                    @click.stop="remove_uploaded_image"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="设施名称:" prop="name">
            <el-input placeholder="输入设施名称" name='name' v-model="supporting_measure.name"></el-input>
          </el-form-item>
          <el-form-item label="设施地址:">
            <el-input placeholder="输入设施地址" name='address' v-model="supporting_measure.address"></el-input>
          </el-form-item>
          <el-form-item label="简介介绍:" prop="introduce">
            <el-input type="textarea" :rows="2" name='introduce' placeholder="输入简介介绍" v-model="supporting_measure.introduce"></el-input>
          </el-form-item>
        </el-form>
        <!-- 底部区域 -->
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="addFacilities">确 定</el-button>
        </span>
    </el-dialog>
    <!-- 修改配套设施的对话框 -->
    <el-dialog :visible.sync="dialogVisibleEdit2">
      <img width="100%" :src="imageUrlEdit" alt="">
    </el-dialog>
    <el-dialog title="编辑配套设施" :visible.sync="dialogVisibleEdit" :closeOnClickModal="false" :center="true" width="50%">
        <!-- 内容主体区 -->
        <el-form id='addFormRefEdit' :model="get_supporting_measure" :rules="addRules"  label-width="100px">
          <el-form-item label="封面：">
            <el-upload
              class="avatar-uploader"
              action=""
              accept="image/jpeg,image/gif,image/png"
              name="photo"
              :on-change="onUploadChangeEdit"
              :auto-upload="false"
              :show-file-list="false">
              <img v-if="imageUrlEdit" :src="imageUrlEdit" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <div v-if="imageUrlEdit" class="el-upload-list--picture-card">
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-preview"
                    @click.stop="show_uploaded_image_edit"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                </span>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="设施名称:" prop="name">
            <el-input placeholder="输入设施名称" name='name' v-model="get_supporting_measure.name"></el-input>
          </el-form-item>
          <el-form-item label="设施地址:">
            <el-input placeholder="输入设施地址" name='address' v-model="get_supporting_measure.address"></el-input>
          </el-form-item>
          <el-form-item label="简介介绍:" prop="introduce">
            <el-input type="textarea" :rows="2" name='introduce' placeholder="输入简介介绍" v-model="get_supporting_measure.introduce"></el-input>
          </el-form-item>
        </el-form>
        <!-- 底部区域 -->
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisibleEdit = false">取 消</el-button>
            <el-button type="primary" @click="addFacilitiesEdit">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 服务器地址
      // serverAddress: 'http://192.168.194.21/Belem/storage/app/',
      serverAddress: 'https://res.bjstarfish.com/',
      // 车位概况的表单数据
      parking_situation: {},
      // 获取车位概况的表单数据
      getParking_situation: {},
      // 园区整体运营情况的数据
      the_whole_operation: {},
      // 13号楼的数据
      the_floor_data: {},
      operateForm2: {},
      operateArr: {},
      operateArr2: {},
      tableData: [],
      // 控制新增配套设施对话框的显示与隐藏
      dialogVisible: false,
      // 编辑按钮的对话框
      dialogVisibleEdit: false,
      // 配套设施的规则
      addRules: {
        name: [
          { required: true, message: '请输入设施名称', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入设施地址', trigger: 'blur' }
        ],
        introduce: [
          { required: true, message: '请输入简介介绍', trigger: 'blur' }
        ]
      },
      supporting_measure: {
        photo: '',
        name: '',
        address: '',
        introduce: ''
      },
      get_supporting_measure: {},
      imageUrl: null,
      imageUrlEdit: null,
      dialogVisible2: false,
      dialogVisibleEdit2: false,
      operateChange_timer_obj: {}
    }
  },
  async created () {
    // 获取车位概况的数据
    const { data: res } = await this.$http.get('parking_situation')
    this.parking_situation[0] = res.data
    for (const key in this.parking_situation[0][0]) {
      this.parking_situation[0][0][key] = Number(this.parking_situation[0][0][key])
    }
    this.getParking_situation = this.parking_situation[0][0]
    this.getwholeData()
    this.getwholeData13()
    this.supporting()
  },
  computed: {
    // 计算未出租
    calculation_not_rent: function () {
      return this.getParking_situation.car_num - this.getParking_situation.have_rent
    },
    // 计算出租率
    calculation_occupancy_rate: function () {
      return Math.round(this.getParking_situation.have_rent / this.getParking_situation.car_num * 100)
    }
  },
  methods: {
    // 修改车位概况的方法
    async situation () {
      if (this.getParking_situation.car_num !== '' && this.getParking_situation.have_rent !== '') {
        // 计算未出租
        this.getParking_situation.not_rent = this.getParking_situation.car_num - this.getParking_situation.have_rent
        // 计算出租率
        this.getParking_situation.occupancy_rate = this.getParking_situation.have_rent / this.getParking_situation.car_num
        const { data: res } = await this.$http.post(`parking_situation/${this.getParking_situation.id}`, {
          car_num: this.getParking_situation.car_num,
          have_rent: this.getParking_situation.have_rent,
          not_rent: this.getParking_situation.not_rent,
          occupancy_rate: this.getParking_situation.occupancy_rate,
          _method: 'put'
        })
        if (res.msg === 'success') this.$message({ message: '保存成功', type: 'success' })
      }
    },
    // 获取园区整体运营情况的数据
    async getwholeData () {
      const { data: res } = await this.$http.get('the_whole_operation')
      this.the_whole_operation = res.data
    },
    // 获取13号楼的数据
    async getwholeData13 () {
      const { data: res } = await this.$http.get('the_floor')
      this.the_floor_data = res.data
    },
    // 获取配套设施的数据
    async supporting () {
      const { data: res } = await this.$http.get('supporting_measure')
      // console.log(res)
      this.tableData = res.data
    },
    operateChange_timer (index, id) {
      clearTimeout(this.operateChange_timer_obj[index])
      this.operateChange_timer_obj[index] = setTimeout(() => {
        this.operateChange(index, id)
      }, 500)
    },
    operateChange_timer2 (index, id) {
      clearTimeout(this.operateChange_timer_obj[index])
      this.operateChange_timer_obj[index] = setTimeout(() => {
        this.operateChange13(index, id)
      }, 500)
    },
    // 修改园区整体运营情况
    async operateChange (index, id) {
      if (this.the_whole_operation[index].house_num !== '' && this.the_whole_operation[index].area !== '' && this.the_whole_operation[index].status !== '' && this.the_whole_operation[index].object !== '') {
        const { data: res } = await this.$http.post(`the_whole_operation/${id}`, {
          _method: 'put',
          house_num: this.the_whole_operation[index].house_num,
          area: this.the_whole_operation[index].area,
          status: this.the_whole_operation[index].status,
          object: this.the_whole_operation[index].object
        })
        if (res) this.$message({ message: '保存成功', type: 'success' })
      }
    },
    // 修改13号楼的运营情况
    async operateChange13 (index, id) {
      if (this.the_floor_data[index].floor !== '' && this.the_floor_data[index].floor_area !== '' && this.the_floor_data[index].status !== '' && this.the_floor_data[index].object !== '') {
        const { data: res } = await this.$http.post(`the_floor/${id}`, {
          _method: 'put',
          floor: this.the_floor_data[index].floor,
          floor_area: this.the_floor_data[index].floor_area,
          status: this.the_floor_data[index].status,
          object: this.the_floor_data[index].object
        })
        if (res) this.$message({ message: '保存成功', type: 'success' })
        // if (data.data.msg === 'success') this.$message({ message: '操作成功', type: 'success' })
      }
    },
    // 添加园区整体运营情况
    async addChange () {
      const { data: res } = await this.$http.post('the_whole_operation', {
        house_num: '0',
        area: '0',
        status: '招商中',
        object: '0'
      })
      this.the_whole_operation.push({
        area: res.data.area,
        created_at: res.data.created_at,
        house_num: res.data.house_num,
        id: res.data.id,
        object: res.data.object,
        status: res.data.status,
        updated_at: res.data.updated_at
      })
      // console.log(res)
      // this.getwholeData()
      if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
    },
    // 添加13号楼的营情况
    async addChange13 () {
      const { data: res } = await this.$http.post('the_floor', {
        floor: '0',
        floor_area: '0',
        status: '招商中',
        object: '0'
      })
      this.getwholeData13()
      if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
    },
    // 删除园区整体运营情况
    async removeChange (id) {
      const { data: res } = await this.$http.delete(`the_whole_operation/${id}`)
      this.getwholeData()
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
    },
    // 删除13号楼运营情况
    async removeChange13 (id) {
      const { data: res } = await this.$http.delete(`the_floor/${id}`)
      this.getwholeData13()
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
    },
    // 新增配套设施
    addFacilities () {
      const data = new FormData(document.getElementById('addFormRef'))
      console.log(data)
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return false
        const { data: res } = await this.$http.post('supporting_measure', data)
        this.dialogVisible = false
        if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
        this.supporting()
      })
    },
    // 编辑配套设施
    async showEditDialog (id) {
      this.dialogVisibleEdit = true
      const { data: res } = await this.$http.get(`supporting_measure/${id}`)
      this.get_supporting_measure = res.data
      this.imageUrlEdit = this.serverAddress + res.data.photo
    },
    // 确定编辑配套设施
    async addFacilitiesEdit () {
      const data = new FormData(document.getElementById('addFormRefEdit'))
      console.log(data)
      data.append('_method', 'put')
      const { data: res } = await this.$http.post(`supporting_measure/${this.get_supporting_measure.id}`, data)
      console.log(res)
      this.dialogVisibleEdit = false
      this.supporting()
    },
    // 删除配套设施
    async removeInfo (id) {
      const { data: res } = await this.$http.delete(`supporting_measure/${id}`)
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
      this.supporting()
    },
    onUploadChange (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.imageUrl = e.currentTarget.result
      }
    },
    remove_uploaded_image () {
      this.imageUrl = false
    },
    show_uploaded_image () {
      this.dialogVisible2 = true
    },
    onUploadChangeEdit (file) {
      const isIMAGE = (file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif')
      if (!isIMAGE) {
        this.$message.error('上传文件只能是图片格式!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file.raw)
      reader.onload = e => {
        this.imageUrlEdit = e.currentTarget.result
      }
    },
    show_uploaded_image_edit () {
      this.dialogVisibleEdit2 = true
    }
  }
}
</script>

<style lang="less" scoped>
.attract_cont{
  padding: 1rem;
  .parking,.operate,.facilities{
    margin-bottom: 1rem;
    .operTitle{
      font-size: 1.25rem;
      font-weight: bold;
      margin-top: 2rem;
      padding: 1rem 0;
    }
    .parking_title,.operate_title,.facilities_title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1.25rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .parkingFrom{
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      padding: 1rem;
      .el-form-item{
        width: 20%;
        text-align: center;
        .el-input{
          width: 75%;
        }
      }
    }
    .operateForm{
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .el-form-item,.oper_input{
        width: 20%;
        text-align: center;
      }
      .form_btn{
        width: 10%;
        display: flex;
        font-size: 1.25rem;
      }
    }
    .operTitle_cont{
      width: 35%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span{
        width: 23%;
        font-size: 14px;
        color: #606266;
        text-align: center;
        padding: 0.5rem 0;
      }
    }
  }
  .facilities{
    .facilities_btn{
      margin: 1rem;
    }
  }
}
</style>
